exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-mdx-frontmatter-type-mdx-frontmatter-slug-js-content-file-path-blog-dataset-i-index-mdx": () => import("./../../../src/pages/{mdx.frontmatter__type}/{mdx.frontmatter__slug}.js?__contentFilePath=/opt/build/repo/blog/dataset-I/index.mdx" /* webpackChunkName: "component---src-pages-mdx-frontmatter-type-mdx-frontmatter-slug-js-content-file-path-blog-dataset-i-index-mdx" */),
  "component---src-pages-mdx-frontmatter-type-mdx-frontmatter-slug-js-content-file-path-blog-dataset-ii-index-mdx": () => import("./../../../src/pages/{mdx.frontmatter__type}/{mdx.frontmatter__slug}.js?__contentFilePath=/opt/build/repo/blog/dataset-II/index.mdx" /* webpackChunkName: "component---src-pages-mdx-frontmatter-type-mdx-frontmatter-slug-js-content-file-path-blog-dataset-ii-index-mdx" */),
  "component---src-pages-mdx-frontmatter-type-mdx-frontmatter-slug-js-content-file-path-blog-dataset-iii-index-mdx": () => import("./../../../src/pages/{mdx.frontmatter__type}/{mdx.frontmatter__slug}.js?__contentFilePath=/opt/build/repo/blog/dataset-III/index.mdx" /* webpackChunkName: "component---src-pages-mdx-frontmatter-type-mdx-frontmatter-slug-js-content-file-path-blog-dataset-iii-index-mdx" */),
  "component---src-pages-mdx-frontmatter-type-mdx-frontmatter-slug-js-content-file-path-posters-esww-2023-mdx": () => import("./../../../src/pages/{mdx.frontmatter__type}/{mdx.frontmatter__slug}.js?__contentFilePath=/opt/build/repo/posters/esww2023.mdx" /* webpackChunkName: "component---src-pages-mdx-frontmatter-type-mdx-frontmatter-slug-js-content-file-path-posters-esww-2023-mdx" */),
  "component---src-pages-mdx-frontmatter-type-mdx-frontmatter-slug-js-content-file-path-publications-exoplanet-cadence-mnras-2023-mdx": () => import("./../../../src/pages/{mdx.frontmatter__type}/{mdx.frontmatter__slug}.js?__contentFilePath=/opt/build/repo/publications/exoplanet_cadence_mnras_2023.mdx" /* webpackChunkName: "component---src-pages-mdx-frontmatter-type-mdx-frontmatter-slug-js-content-file-path-publications-exoplanet-cadence-mnras-2023-mdx" */),
  "component---src-pages-posters-js": () => import("./../../../src/pages/posters.js" /* webpackChunkName: "component---src-pages-posters-js" */),
  "component---src-pages-publications-js": () => import("./../../../src/pages/publications.js" /* webpackChunkName: "component---src-pages-publications-js" */)
}

